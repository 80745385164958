<template>
  <v-card class="upload-card fill-height" flat>
    <v-data-table
      v-if="items"
      v-bind="{ headers, items }"
      :hide-actions="pagination.totalItems === 0"
      :pagination.sync="pagination"
      :rows-per-page-items="[10]"
      :total-items="serverData.total"
    >
      <tr slot="items" slot-scope="props">
        <td>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-avatar
                v-on="on"
                :class="props.item.stage | statusColor"
                size="16"
              />
            </template>
            <span>{{ props.item.stage | statusDescription }}</span>
          </v-tooltip>
        </td>
        <td>{{ props.item.file_name }}</td>
        <td>
          {{ props.item.channel | channelDescription }}
          <template v-if="props.item.user"
            >({{ props.item.user.username }})</template
          >
        </td>

        <td>{{ props.item.created_at | formatDate }}</td>
        <td class="text-sm-right">
          <v-btn icon @click="showItem(props.item)"
            ><v-icon>info</v-icon></v-btn
          >
          <v-btn
            v-if="props.item.file_path"
            icon
            @click="downloadPdf(props.item.download_path)"
          >
            <v-icon>cloud_download</v-icon>
          </v-btn>
        </td>
      </tr>
      <template slot="pageText" slot-scope="props">
        Einträge {{ props.pageStart }} bis {{ props.pageStop }} von insgesamt
        {{ props.itemsLength }}
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="80%">
      <v-card v-if="selectedItem">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ selectedItem.file_name }}</v-toolbar-title>
          <v-spacer />
          <v-btn dark icon @click="showItem(null)">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-layout mb-4>
            <v-flex class="grey lighten-2 padding p-4">
              <pre class="ma-4" style="overflow-x: auto">{{
                JSON.stringify(selectedItem, null, 2)
              }}</pre>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import documentsApi from '@/api/documentsApi'
import formatDate from 'date-fns/format'

export default {
  name: 'AuditTrail',
  data() {
    return {
      dialog: false,
      selectedItem: null,
      loading: false,
      headers: [
        { text: '', sortable: false },
        { text: 'Dokument', value: 'name', sortable: false },
        { text: 'Kanal', value: 'address', sortable: false },
        { text: 'Datum', value: 'address', sortable: false },
        { text: '', sortable: false, align: 'right' },
      ],
      serverData: null,
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortBy: 'date',
      },
    }
  },
  computed: {
    items() {
      return this.serverData?.data
    },
  },
  methods: {
    showItem(item) {
      this.selectedItem = item
      this.dialog = !!item
    },
    async loadPage(page) {
      if (!this.loading) {
        this.loading = true
        this.serverData = await documentsApi.getAuditTrails({ page })
        this.pagination.totalItems = this.serverData.total
        this.pagination.page = this.serverData.current_page
        this.pagination.rowsPerPage = this.serverData.per_page
        this.loading = false
      }
    },
    downloadPdf(pdf) {
      documentsApi.downloadPDF(pdf)
    },
  },
  filters: {
    statusColor(stage) {
      return {
        received: 'error',
        queued: 'warning',
        processed: 'success',
      }[stage]
    },
    statusDescription(stage) {
      return {
        received: 'Fehler von OCR',
        queued: 'An OCR übergeben',
        processed: 'Importiert',
      }[stage]
    },
    channelDescription(channel) {
      return (
        {
          manual: 'Manueller Upload',
          companyInbox: 'Autom. Posteingang',
        }[channel] || channel
      )
    },
    formatDate(date) {
      return formatDate(new Date(date), 'DD.MM.YYYY (HH:mm) [Uhr]')
    },
  },
  created() {
    this.intervallId = setInterval(() => {
      this.loadPage(this.pagination.page)
    }, 5000)
  },
  destroyed() {
    clearInterval(this.intervallId)
  },
  watch: {
    'pagination.page': {
      handler: 'loadPage',
      immediate: true,
    },
  },
}
</script>

<style scoped></style>
